<template>
  <div
    class="user-page-sidebar"
    :class="bem('user-page-sidebar', { status: placeholderStatus })"
  >
    <SectionGroup gap="medium">
      <SectionBox>
        <BasicSection>
          <div class="user-page-sidebar__avatar">
            <BasicAvatar
              class="mq-mobile-only"
              :image="user.avatar.image"
              :href="user.avatar.href"
              target="_blank"
              size="medium-large"
              roundness="full"
              data-lightbox="mobile"
            />
            <BasicAvatar
              class="mq-not-mobile"
              :image="user.avatar.image"
              :href="user.avatar.href"
              target="_blank"
              size="extra-large"
              roundness="full"
              data-lightbox="desktop"
            />
          </div>
          <div class="user-page-sidebar__title">
            <BasicLink :href="user.href" appearance="dark">
              <PageTitle :title="user.title" />
            </BasicLink>
          </div>
          <div
            v-if="user.lastActivity"
            class="user-page-sidebar__last-activity"
          >
            <BasicText color="muted" size="small">
              {{ user.lastActivity }}
            </BasicText>
          </div>
          <div v-if="user.careerStaff" class="user-page-sidebar__is-habr-admin">
            <HabrAdminLabel />
          </div>
          <div v-if="user.isExpert" class="user-page-sidebar__is-expert">
            <ExpertLabel />
          </div>
          <div v-if="user.site" class="user-page-sidebar__site">
            <BasicLink
              :href="user.site.href"
              :title="user.site.title"
              target="_blank"
              rel="nofollow"
            />
          </div>
          <div v-if="userMeta.length !== 0" class="user-page-sidebar__meta">
            <InlineList :items="userMeta" v-slot="{ item }">
              <LinkOrText :item="item" />
            </InlineList>
          </div>
          <div v-if="userCareer.length !== 0" class="user-page-sidebar__career">
            <BasicText weight="bold" color="emphasized">
              <InlineList
                separator-color="inherit"
                :items="userCareer"
                v-slot="{ item }"
              >
                <LinkOrText appearance="inherit" :item="item" />
              </InlineList>
            </BasicText>
          </div>
          <div v-if="user.jobInviteButton">
            <div class="user-page-sidebar__actions">
              <JobInviteButton
                :job-invite-button="user.jobInviteButton"
                invited-from="profile"
                :company-id="companyId"
                full-width
                @success="setJobInviteState"
              />

              <div class="user-page-sidebar__actions-group">
                <div>
                  <BasicTooltip
                    v-if="isBlocked"
                    :content="tooltipTextForBlockedState"
                    :max-width="225"
                    placement="top"
                    theme="dark"
                  >
                    <BasicButton
                      :href="null"
                      appearance="secondary"
                      full-width
                      size="m"
                      disabled
                      title="Написать"
                    >
                      Написать
                    </BasicButton>
                  </BasicTooltip>

                  <BasicButton
                    v-else-if="user.conversation"
                    :href="user.conversation.href"
                    appearance="secondary"
                    full-width
                    size="m"
                    title="Написать"
                  >
                    {{ user.conversation.title }}
                  </BasicButton>
                </div>

                <div class="user-page-sidebar__actions-icons">
                  <FriendshipButton
                    v-if="user.friendship"
                    v-model="user.friendship"
                    @update:modelValue="() => $forceUpdate()"
                    :user-id="user.id"
                    size="m"
                    appearance="icon-only"
                  />

                  <UserSidebarContextMenu
                    @report="reportModalVisible = true"
                    :user.sync="user"
                    :visitor="visitor"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="user-page-sidebar__actions">
            <BasicButton
              v-if="user.personalHref"
              :href="user.personalHref"
              appearance="secondary"
              size="m"
            >
              Личный кабинет
            </BasicButton>
            <FriendshipButton
              v-if="user.friendship"
              v-model="user.friendship"
              @update:modelValue="() => $forceUpdate()"
              :user-id="user.id"
              size="m"
            />
            <BasicButton
              v-if="user.conversation"
              appearance="secondary"
              size="m"
              :href="user.conversation.href"
              rel="noindex, nofollow"
            >
              {{ user.conversation.title }}
            </BasicButton>

            <UserSidebarContextMenu
              @report="reportModalVisible = true"
              :user.sync="user"
              :visitor="visitor"
            />
          </div>
        </BasicSection>
      </SectionBox>

      <SectionBox v-if="user.contacts">
        <BasicSection>
          <UserContacts
            :contacts="user.contacts"
            :visitor="visitor"
            :user-login="user.id"
            :curator-login="curatorLogin"
            @openContacts="$emit('openContacts')"
          />
        </BasicSection>
      </SectionBox>

      <SectionBox v-if="user.shouldDisplayInfo">
        <BasicSection>
          <SectionGroup>
            <SectionGroup
              v-if="
                user.age ||
                user.experience ||
                user.registeredAt ||
                user.lastVisited ||
                user.isOwner
              "
              gap="smallest"
            >
              <div v-if="typeof user.score === 'number'">
                <BasicText color="muted"> Профиль заполнен на:</BasicText>
                {{ user.score }}%
              </div>
              <div v-if="user.age">
                <BasicText color="muted"> Возраст:</BasicText>
                {{ user.age }}
              </div>
              <div v-else-if="user.isOwner">
                <BasicText color="important"> Возраст:</BasicText>
                <BasicText color="muted"> не указан</BasicText>
              </div>
              <div v-if="user.experience">
                <BasicText color="muted"> Опыт работы:</BasicText>
                {{ user.experience }}
              </div>
              <div v-if="user.registeredAt">
                <BasicText color="muted"> Регистрация:</BasicText>
                {{ user.registeredAt }}
              </div>
              <div v-if="user.lastVisited">
                <BasicText color="muted"> Последний визит:</BasicText>
                {{ user.lastVisited }}
              </div>
            </SectionGroup>

            <SectionGroup v-if="showLocationCitizenshipSection" gap="smallest">
              <div v-if="user.citizenships && user.citizenships.length">
                <BasicText color="muted"> Гражданство: </BasicText>
                <InlineList :items="user.citizenships" v-slot="{ item }">
                  <span v-text="item.title" />
                </InlineList>
              </div>
              <div v-if="location.length !== 0">
                <BasicText color="muted"> Местоположение: </BasicText>
                <InlineList :items="location" v-slot="{ item }">
                  <span v-text="item" />
                </InlineList>
              </div>
              <div
                v-if="user.isOwner && location.length === 0 && !user.relocation"
              >
                <BasicText color="important"> Местоположение:</BasicText>
                <BasicText color="muted"> не указано</BasicText>
              </div>
            </SectionGroup>

            <div v-if="user.relocation && user.relocation.length !== 0">
              <BasicText color="muted"> Дополнительно:</BasicText>
              <BasicText>
                {{ user.relocation }}
              </BasicText>
            </div>
            <div
              v-if="user.specializations && user.specializations.length !== 0"
            >
              <div>
                <BasicText color="muted"> Специализация</BasicText>
                <InlineList :items="user.specializations" v-slot="{ item }">
                  <BasicText>
                    {{ item.title }}
                  </BasicText>
                </InlineList>
              </div>
            </div>
            <div v-if="user.foreignLanguages.length">
              <BasicText color="muted"> Знание языков: </BasicText>
              <InlineList :items="user.foreignLanguages" v-slot="{ item }">
                <span v-text="item.title" />
              </InlineList>
            </div>
            <template
              v-if="user.curatedCompanies && user.curatedCompanies.length !== 0"
            >
              <div class="user-page-sidebar__delimiter" />
              <SectionGroup gap="smallest">
                <BasicText weight="bold"> Куратор</BasicText>
                <SeparatedList
                  :items="user.curatedCompanies"
                  gap="small"
                  v-slot="{ item }"
                >
                  <CompanyListItem :company="item" />
                </SeparatedList>
              </SectionGroup>
            </template>
            <template
              v-if="user.curatedPlatforms && user.curatedPlatforms.length !== 0"
            >
              <div class="user-page-sidebar__delimiter" />
              <SectionGroup gap="smallest">
                <BasicText weight="bold">
                  Куратор школ допобразования
                </BasicText>
                <SeparatedList
                  :items="user.curatedPlatforms"
                  gap="small"
                  v-slot="{ item }"
                >
                  <CompanyListItem :company="item" />
                </SeparatedList>
              </SectionGroup>
            </template>
            <BasicText v-if="user.visibility" color="muted" size="small">
              <LinkOrText :item="user.visibility" appearance="dimmed" />
            </BasicText>
          </SectionGroup>
        </BasicSection>
      </SectionBox>

      <AchievementsSidebarCard
        v-if="shouldDisplayAchievements"
        :achievements="user.achievements"
        :user="user"
      />

      <SectionBox v-if="user.friends || user.recommendationLetters">
        <BasicSection>
          <SectionGroup>
            <BasicLink
              v-if="user.recommendationLetters"
              appearance="dark"
              :href="user.recommendationLetters.href"
            >
              <OppositeText
                left="Рекомендательные письма"
                :right="user.recommendationLetters.total"
              />
            </BasicLink>
            <BasicLink
              v-if="user.friends"
              appearance="dark"
              :href="user.friends.href"
            >
              <OppositeText left="Друзья" :right="user.friends.total" />
            </BasicLink>
          </SectionGroup>
        </BasicSection>
      </SectionBox>
    </SectionGroup>

    <TransitionFade v-if="user.report" body-scroll-lock>
      <ReportModal
        v-if="reportModalVisible"
        :options="user.report"
        :user-id="user.id"
        @close="reportModalVisible = false"
      />
    </TransitionFade>
  </div>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import AchievementsSidebarCard from 'src/components/achievements/AchievementsSidebarCard/AchievementsSidebarCard.vue';
import BasicAvatar from 'src/components/BasicAvatar/BasicAvatar.vue';
import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicLink from 'src/components/BasicLink/BasicLink.vue';
import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
import FriendshipButton from 'src/components/buttons/FriendshipButton/FriendshipButton.vue';
import CompanyListItem from 'src/components/companies/CompanyListItem/CompanyListItem.vue';
import ExpertLabel from 'src/components/experts/ExpertLabel/ExpertLabel.vue';
import HabrAdminLabel from 'src/components/HabrAdmin/HabrAdminLabel/HabrAdminLabel.vue';
import InlineList from 'src/components/InlineList/InlineList.vue';
import JobInviteButton from 'src/components/JobInviteButton/JobInviteButton.vue';
import LinkOrText from 'src/components/LinkOrText/LinkOrText.vue';
import PageTitle from 'src/components/PageTitle/PageTitle.vue';
import ReportModal from 'src/components/ReportModal/ReportModal.vue';
import SectionBox from 'src/components/SectionBox/SectionBox.vue';
import BasicSection from 'src/components/sections/BasicSection/BasicSection.vue';
import SectionGroup from 'src/components/sections/SectionGroup/SectionGroup.vue';
import SeparatedList from 'src/components/SeparatedList/SeparatedList.vue';
import BasicText from 'src/components/text/BasicText/BasicText.vue';
import OppositeText from 'src/components/text/OppositeText/OppositeText.vue';
import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';
import UserContacts from 'src/components/users/UserContacts/UserContacts.vue';
import UserSidebarContextMenu from 'src/components/users/UserSidebarContextMenu.vue';

import lockIcon from 'images/no_content.icon.response.svg';

export default {
  name: 'UserPageSidebar',
  components: {
    ExpertLabel,
    AchievementsSidebarCard,
    CompanyListItem,
    SeparatedList,
    ReportModal,
    TransitionFade,
    OppositeText,
    BasicButton,
    LinkOrText,
    FriendshipButton,
    InlineList,
    BasicLink,
    BasicText,
    BasicTooltip,
    PageTitle,
    BasicAvatar,
    BasicSection,
    SectionBox,
    SectionGroup,
    UserContacts,
    JobInviteButton,
    UserSidebarContextMenu,
    HabrAdminLabel,
  },
  inject: {
    visitor: {
      default: null,
    },
    setJobInviteState: {
      default: () => {},
    },
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: Object,
    },
  },
  contactsPlaceholderIcon: {
    src: lockIcon,
    width: 86,
    height: 98,
  },
  emits: ['openContacts'],
  data() {
    return {
      reportModalVisible: false,
    };
  },
  methods: {
    bem,
  },
  computed: {
    curatorLogin() {
      if (!this.visitor?.company) return null;

      return this.visitor?.login;
    },
    companyId() {
      return this.visitor?.company?.id;
    },
    shouldDisplayAchievements() {
      if (!this.user.shouldDisplayInfo) return false;

      return Boolean(this.user.achievements && this.user.achievements.length);
    },
    userMeta() {
      const { specialization, divisions, qualification } = this.user;
      const res = [];
      if (specialization) {
        res.push({
          title: specialization.title,
          href: specialization.edit,
        });
      }
      if (divisions) {
        res.push(...divisions);
      }
      if (qualification) {
        res.push({ title: qualification });
      }
      return res;
    },
    userCareer() {
      const { salary, availability, personalHref } = this.user;
      const res = [];
      if (salary) {
        res.push({
          href: personalHref ? '/profile/specialization' : null,
          title: salary,
        });
      }
      if (availability) {
        res.push({
          href: personalHref ? '/profile/specialization' : null,
          title: availability,
        });
      }
      return res;
    },
    location() {
      const { location } = this.user;
      const res = [];
      if (location) {
        res.push(location);
      }
      return res;
    },
    // eslint-disable-next-line complexity
    showLocationCitizenshipSection() {
      if (this.user.citizenships && this.user.citizenships.length) {
        return true;
      }

      if (this.location.length !== 0) {
        return true;
      }

      return (
        this.user.isOwner && this.location.length === 0 && !this.user.relocation
      );
    },
    placeholderStatus() {
      if (!this.placeholder) return '';
      return this.placeholder.status;
    },
    isBlocked() {
      return this.user.jobInviteButton.state.startsWith('blocked_');
    },
    tooltipTextForBlockedState() {
      switch (this.user.jobInviteButton?.state) {
        case 'blocked_by_user':
          return 'Специалист вас заблокировал — вы не можете написать ему';
        case 'blocked_by_you':
          return 'Вы заблокировали специалиста и не можете написать ему';
        default:
          return null;
      }
    },
  },
};
</script>

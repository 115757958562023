<template>
  <WithSpinnerPlaceholder
    v-if="currentImport"
    appearance="career-web"
    :loading="isLoading"
    :size="26"
    class="hh-import-profile-status-banner__placeholder"
  >
    <section
      class="hh-import-profile-status-banner"
      :class="
        bem('hh-import-profile-status-banner', {
          inProgress,
          failed,
          succeeded,
        })
      "
      aria-labelledby="hh-import-profile-title"
    >
      <PostponeCircleAnimated
        v-if="iconParams.icon === 'postpone-circle' && isConnected"
        class="hh-import-profile-status-banner__icon"
        width="24"
        height="24"
      />
      <SvgIcon
        v-else
        class="hh-import-profile-status-banner__icon"
        :icon="iconParams.icon"
        :color="iconParams.color"
        size="24"
      />

      <div
        class="hh-import-profile-status-banner__content"
        :class="
          bem('hh-import-profile-status-banner__content', {
            inProgress,
            failed,
            succeeded,
          })
        "
      >
        <div class="hh-import-profile-status-banner__header">
          <h2
            class="hh-import-profile-status-banner__title"
            id="hh-import-profile-title"
          >
            {{ bannerElements.title }}
          </h2>

          <template v-if="bannerElements.action">
            <InlineSeparator />
            <button
              type="button"
              class="hh-import-profile-status-banner__action"
              @click.prevent="invokeAction(bannerElements.action)"
            >
              {{ actionLabel }}
            </button>
          </template>
        </div>

        <p
          v-if="bannerElements.message"
          class="hh-import-profile-status-banner__message"
          v-html="bannerElements.message"
        />

        <button
          v-if="bannerElements.canBeCanceled"
          type="button"
          class="hh-import-profile-status-banner__cancel"
          @click.prevent="invokeAction('cancel')"
        >
          Отменить
        </button>
      </div>

      <button
        v-if="bannerElements.canBeClosed"
        title="Закрыть"
        class="hh-import-profile-status-banner__close"
        @click.prevent="invokeAction('close')"
      >
        <SvgIcon icon="cross" color="muted" size="12" />
      </button>
    </section>

    <TransitionFade body-scroll-lock>
      <HHImportConfirmRestoringModal
        v-if="showConfirmRestoring"
        @restore="acceptChanges(false)"
        @close="showConfirmRestoring = false"
      />
    </TransitionFade>

    <TransitionFade body-scroll-lock>
      <HHImportConfirmSavingModal
        v-if="showConfirmSaving"
        @close="showConfirmSaving = false"
        @accept="acceptChanges(true)"
      />
    </TransitionFade>
  </WithSpinnerPlaceholder>
</template>

<script>
import { notify } from 'src/notify.js';
import { ProfileHHImportService } from 'src/services/profile.js';
import { bem } from 'src/utils/bem.js';

import PostponeCircleAnimated from 'src/components/animations/PostponeCircleAnimated.vue';
import HHImportConfirmRestoringModal from 'src/components/hh_import_profile/HHImportConfirmRestoringModal/HHImportConfirmRestoringModal.vue';
import HHImportConfirmSavingModal from 'src/components/hh_import_profile/HHImportConfirmSavingModal/HHImportConfirmSavingModal.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';
import InlineSeparator from 'src/components/text/InlineSeparator/InlineSeparator.vue';
import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';

import { usePusher } from 'src/services/pusher';

export default {
  name: 'HHImportProfileStatusBanner',
  components: {
    SvgIcon,
    TransitionFade,
    InlineSeparator,
    PostponeCircleAnimated,
    WithSpinnerPlaceholder,
    HHImportConfirmSavingModal,
    HHImportConfirmRestoringModal,
  },
  props: {
    hhImport: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:hhImport'],
  setup(props, ctx) {
    const { pusher, isConnected } = usePusher();

    pusher.on(
      'import_profile_status_updated',
      ({ data: { currentImport } }) => {
        ctx.emit('update:hhImport', {
          ...props.hhImport,
          currentImport,
        });
      },
    );

    return { pusher, isConnected };
  },
  data() {
    return {
      isLoading: false,
      showConfirmRestoring: false,
      showConfirmSaving: false,
    };
  },
  methods: {
    bem,
    invokeAction(action) {
      switch (action) {
        case 'update': {
          this.updateCurrentImport();
          break;
        }
        case 'restoreProfile': {
          this.showConfirmRestoring = true;
          break;
        }
        case 'cancel':
        case 'close': {
          this.closeBanner();
          break;
        }
        default:
      }
    },
    async updateCurrentImport() {
      this.isLoading = true;

      window.location.reload();
    },
    async acceptChanges(accept) {
      this.showConfirmSaving = false;
      this.showConfirmRestoring = false;
      this.isLoading = true;

      const successMessage = accept
        ? 'Профиль успешно сохранен'
        : 'Профиль успешно восстановлен';
      const fallbackErrorMessage = accept
        ? 'Cохранить профиль не удалось. Попробуйте позже'
        : 'Восстановить профиль не удалось. Попробуйте позже';

      try {
        const data = { acceptChanges: accept };
        await ProfileHHImportService.patch({ data });
        const { data: newHhImport } = await ProfileHHImportService.get();

        this.$emit('update:hhImport', newHhImport);

        notify({ message: successMessage });

        window.location.reload();
      } catch (updateStatusError) {
        const message = updateStatusError?.message ?? fallbackErrorMessage;
        notify({ message, type: 'error' });
      } finally {
        this.isLoading = false;
      }
    },
    closeBanner() {
      if (this.succeeded) {
        this.showConfirmSaving = true;
        return;
      }

      this.deleteImportAndUpdateStatus();
    },
    async deleteImportAndUpdateStatus() {
      this.isLoading = true;

      try {
        await ProfileHHImportService.delete();

        const { data: newHhImport } = await ProfileHHImportService.get();

        if (this.inProgress) notify({ message: 'Импорт успешно отменен' });

        this.$emit('update:hhImport', newHhImport);
      } catch (deletingError) {
        const message =
          deletingError?.message ?? 'Что-то пошло не так. Попробуйте позже';

        notify({ message, type: 'error' });
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    serviceStatus() {
      return this.hhImport.serviceStatus;
    },
    currentImport() {
      return this.hhImport.currentImport;
    },
    inProgress() {
      return this.currentImport?.status === 'inProgress';
    },
    failed() {
      return this.currentImport?.status === 'error';
    },
    succeeded() {
      return this.currentImport?.status === 'success';
    },
    errorDescription() {
      return this.currentImport?.errorDescription;
    },
    iconParams() {
      if (this.inProgress)
        return { icon: 'postpone-circle', color: 'light-blue' };

      if (this.succeeded)
        return { icon: 'check-circle-empty', color: 'ui-green' };

      return { icon: 'minus-circle', color: 'red' };
    },
    bannerElements() {
      if (this.inProgress) {
        return {
          title: 'Заполнение резюме',
          canBeCanceled: true,
          message: null,
          action: this.isConnected ? null : 'update',
          canBeClosed: false,
        };
      }

      if (this.succeeded) {
        return {
          title: 'Резюме успешно обновлено',
          canBeCanceled: false,
          message:
            'Внимательно изучите новые данные в вашем профиле и проверьте, что импорт резюме прошел так, как вы ожидали.',
          action: 'restoreProfile',
          canBeClosed: true,
        };
      }

      return {
        title: 'Ошибка заполнения резюме',
        canBeCanceled: false,
        message: this.errorDescription ?? null,
        action: null,
        canBeClosed: true,
      };
    },
    actionLabel() {
      if (this.bannerElements.action === 'update') return 'Обновить';

      if (this.bannerElements.action === 'restoreProfile')
        return 'Отменить изменения';

      return null;
    },
  },
};
</script>

<template>
  <DecoratedResultModal
    @close="$emit('close')"
    appearance="warning"
    title="Сохранение данных в&nbsp;профиле"
  >
    <template #default>
      <p>
        Нажмите «Подтвердить», если импортированные данные в ваш профиль вас
        устраивают. После этого вернуть профиль к предыдущей версии будет
        нельзя.
      </p>
    </template>
    <template #actions>
      <BasicButton appearance="primary" size="l" @click="$emit('accept')">
        Подтвердить
      </BasicButton>
      <BasicButton appearance="secondary" size="l" @click="$emit('close')">
        Закрыть
      </BasicButton>
    </template>
  </DecoratedResultModal>
</template>

<script>
import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import DecoratedResultModal from 'src/components/DecoratedResultModal/DecoratedResultModal.vue';

export default {
  name: 'HHImportConfirmSavingModal',
  components: { BasicButton, DecoratedResultModal },
  emits: ['close', 'accept'],
};
</script>

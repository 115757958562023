import Vue, { reactive } from 'vue';

import { getSsrState } from 'src/utils/ssr.js';
import { createVueApp } from 'src/utils/vue.js';

import HHImportProfileStatusBanner from 'src/components/hh_import_profile/HHImportProfileStatusBanner/HHImportProfileStatusBanner.vue';
import UserPage from 'src/components/users/UserPage/UserPage.vue';
import VisitorProvider from 'src/components/users/VisitorProvider/VisitorProvider.vue';

const container = document.querySelector('[data-root]');
const ssrState = getSsrState();

if (container) {
  const {
    user: initialUserData,
    resume,
    placeholder,
    visitor,
    hasActiveConnection,
    visibleConnectionFeedback,
    skillsBanner,
  } = ssrState;

  const user = reactive(initialUserData);

  const setJobInviteState = (response, state = 'already_sent') => {
    if (!user.jobInviteButton) return;

    user.jobInviteButton.state = state;

    if (response && response.contacts) {
      user.contacts = response.contacts;
    }
  };

  const app = createVueApp({
    provide() {
      return { setJobInviteState };
    },
    render(h) {
      return h(
        VisitorProvider,
        {
          props: {
            visitor,
          },
        },
        [
          h(UserPage, {
            props: {
              user,
              resume,
              placeholder,
              hasActiveConnection,
              visibleConnectionFeedback,
              skillsBanner,
            },
          }),
        ],
      );
    },
  });

  app.$mount(container);
}

const hhImportStatusBannerContaner = document.querySelector(
  '[data-hh-import-profile-status-banner]',
);

if (hhImportStatusBannerContaner && ssrState.hhImport) {
  const hhImport = reactive(ssrState.hhImport);

  const app = createVueApp({
    render(h) {
      return h(HHImportProfileStatusBanner, {
        props: {
          hhImport,
        },
        on: {
          'update:hhImport'(data) {
            Object.entries(data).forEach(([key, value]) => {
              Vue.set(hhImport, key, value);
            });
          },
        },
      });
    },
  });

  app.$mount(hhImportStatusBannerContaner);
}

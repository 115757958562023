<template>
  <ModalWindow :has-header="false" appearance="new" @close="closeModal($event)">
    <div
      class="decorated-result-modal__image"
      :class="bem('decorated-result-modal__image', { appearance })"
    >
      <svg
        v-if="appearance === 'warning'"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 28H84V75C84 77.7614 81.7614 80 79 80H17C14.2386 80 12 77.7614 12 75V28Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48 36C46.7633 36 45.6213 36.6627 45.0077 37.7365L30.4164 63.2712C30.1435 63.7488 30 64.2893 30 64.8394C30 66.5849 31.4151 68 33.1606 68H62.8394C64.5849 68 66 66.5849 66 64.8394C66 64.2893 65.8565 63.7488 65.5836 63.2712L50.9923 37.7365C50.3787 36.6627 49.2367 36 48 36ZM48 44C49.1046 44 50 44.8954 50 46V56C50 57.1046 49.1046 58 48 58C46.8954 58 46 57.1046 46 56V46C46 44.8954 46.8954 44 48 44ZM50 62C50 63.1046 49.1046 64 48 64C46.8954 64 46 63.1046 46 62C46 60.8954 46.8954 60 48 60C49.1046 60 50 60.8954 50 62Z"
          fill="#F9A21F"
        />
        <path
          d="M12 21C12 18.2386 14.2386 16 17 16H79C81.7614 16 84 18.2386 84 21V28H12V21Z"
          fill="#F9A21F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 24C19.1046 24 20 23.1046 20 22C20 20.8954 19.1046 20 18 20C16.8954 20 16 20.8954 16 22C16 23.1046 16.8954 24 18 24ZM26 24C27.1046 24 28 23.1046 28 22C28 20.8954 27.1046 20 26 20C24.8954 20 24 20.8954 24 22C24 23.1046 24.8954 24 26 24ZM36 22C36 23.1046 35.1046 24 34 24C32.8954 24 32 23.1046 32 22C32 20.8954 32.8954 20 34 20C35.1046 20 36 20.8954 36 22Z"
          fill="white"
        />
      </svg>

      <svg
        v-else-if="appearance === 'success'"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 28H84V75C84 77.7614 81.7614 80 79 80H17C14.2386 80 12 77.7614 12 75V28Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48 70C56.8366 70 64 62.8366 64 54C64 45.1634 56.8366 38 48 38C39.1634 38 32 45.1634 32 54C32 62.8366 39.1634 70 48 70ZM57.4191 50.4094C58.1974 49.6256 58.1931 48.3593 57.4094 47.5809C56.6256 46.8026 55.3593 46.8069 54.5809 47.5906L45.0521 57.185L40.3958 52.6476C39.6047 51.8767 38.3385 51.8931 37.5676 52.6842C36.7967 53.4753 36.8131 54.7415 37.6042 55.5124L43.6793 61.4324C44.4651 62.1981 45.721 62.1878 46.4942 61.4094L57.4191 50.4094Z"
          fill="#48CBAD"
        />
        <path
          d="M12 21C12 18.2386 14.2386 16 17 16H79C81.7614 16 84 18.2386 84 21V28H12V21Z"
          fill="#48CBAD"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 24C19.1046 24 20 23.1046 20 22C20 20.8954 19.1046 20 18 20C16.8954 20 16 20.8954 16 22C16 23.1046 16.8954 24 18 24ZM26 24C27.1046 24 28 23.1046 28 22C28 20.8954 27.1046 20 26 20C24.8954 20 24 20.8954 24 22C24 23.1046 24.8954 24 26 24ZM36 22C36 23.1046 35.1046 24 34 24C32.8954 24 32 23.1046 32 22C32 20.8954 32.8954 20 34 20C35.1046 20 36 20.8954 36 22Z"
          fill="white"
        />
      </svg>

      <svg
        v-else-if="appearance === 'error'"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 28H84V75C84 77.7614 81.7614 80 79 80H17C14.2386 80 12 77.7614 12 75V28Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48 70C56.8366 70 64 62.8366 64 54C64 45.1634 56.8366 38 48 38C39.1634 38 32 45.1634 32 54C32 62.8366 39.1634 70 48 70ZM39 52C37.8954 52 37 52.8954 37 54C37 55.1046 37.8954 56 39 56L57 56C58.1046 56 59 55.1046 59 54C59 52.8954 58.1046 52 57 52L39 52Z"
          fill="#FF7E75"
        />
        <path
          opacity="0.6"
          d="M12 21C12 18.2386 14.2386 16 17 16H79C81.7614 16 84 18.2386 84 21V28H12V21Z"
          fill="#D34C09"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 24C19.1046 24 20 23.1046 20 22C20 20.8954 19.1046 20 18 20C16.8954 20 16 20.8954 16 22C16 23.1046 16.8954 24 18 24ZM26 24C27.1046 24 28 23.1046 28 22C28 20.8954 27.1046 20 26 20C24.8954 20 24 20.8954 24 22C24 23.1046 24.8954 24 26 24ZM36 22C36 23.1046 35.1046 24 34 24C32.8954 24 32 23.1046 32 22C32 20.8954 32.8954 20 34 20C35.1046 20 36 20.8954 36 22Z"
          fill="white"
        />
      </svg>
    </div>

    <div class="decorated-result-modal__content">
      <h2 class="decorated-result-modal__title" v-html="title" />

      <slot />

      <ButtonGroup appearance="inline" class="decorated-result-modal__footer">
        <slot name="actions" />
      </ButtonGroup>
    </div>
  </ModalWindow>
</template>
<script>
import { bem } from 'src/utils/bem.js';

import ButtonGroup from 'src/components/ButtonGroup/ButtonGroup.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';

export default {
  name: 'DecoratedResultModal',
  components: {
    ModalWindow,
    ButtonGroup,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    appearance: {
      type: String,
      required: true,
      validator(value) {
        return ['success', 'warning', 'error'].includes(value);
      },
    },
  },
  emits: ['close'],
  methods: {
    bem,

    closeModal(event) {
      this.$emit('close', event);

      document.body.style.overflow = 'auto scroll';
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentImport)?_c('WithSpinnerPlaceholder',{staticClass:"hh-import-profile-status-banner__placeholder",attrs:{"appearance":"career-web","loading":_vm.isLoading,"size":26}},[_c('section',{staticClass:"hh-import-profile-status-banner",class:_vm.bem('hh-import-profile-status-banner', {
        inProgress: _vm.inProgress,
        failed: _vm.failed,
        succeeded: _vm.succeeded,
      }),attrs:{"aria-labelledby":"hh-import-profile-title"}},[(_vm.iconParams.icon === 'postpone-circle' && _vm.isConnected)?_c('PostponeCircleAnimated',{staticClass:"hh-import-profile-status-banner__icon",attrs:{"width":"24","height":"24"}}):_c('SvgIcon',{staticClass:"hh-import-profile-status-banner__icon",attrs:{"icon":_vm.iconParams.icon,"color":_vm.iconParams.color,"size":"24"}}),_c('div',{staticClass:"hh-import-profile-status-banner__content",class:_vm.bem('hh-import-profile-status-banner__content', {
          inProgress: _vm.inProgress,
          failed: _vm.failed,
          succeeded: _vm.succeeded,
        })},[_c('div',{staticClass:"hh-import-profile-status-banner__header"},[_c('h2',{staticClass:"hh-import-profile-status-banner__title",attrs:{"id":"hh-import-profile-title"}},[_vm._v(" "+_vm._s(_vm.bannerElements.title)+" ")]),(_vm.bannerElements.action)?[_c('InlineSeparator'),_c('button',{staticClass:"hh-import-profile-status-banner__action",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.invokeAction(_vm.bannerElements.action)}}},[_vm._v(" "+_vm._s(_vm.actionLabel)+" ")])]:_vm._e()],2),(_vm.bannerElements.message)?_c('p',{staticClass:"hh-import-profile-status-banner__message",domProps:{"innerHTML":_vm._s(_vm.bannerElements.message)}}):_vm._e(),(_vm.bannerElements.canBeCanceled)?_c('button',{staticClass:"hh-import-profile-status-banner__cancel",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.invokeAction('cancel')}}},[_vm._v(" Отменить ")]):_vm._e()]),(_vm.bannerElements.canBeClosed)?_c('button',{staticClass:"hh-import-profile-status-banner__close",attrs:{"title":"Закрыть"},on:{"click":function($event){$event.preventDefault();return _vm.invokeAction('close')}}},[_c('SvgIcon',{attrs:{"icon":"cross","color":"muted","size":"12"}})],1):_vm._e()],1),_c('TransitionFade',{attrs:{"body-scroll-lock":""}},[(_vm.showConfirmRestoring)?_c('HHImportConfirmRestoringModal',{on:{"restore":function($event){return _vm.acceptChanges(false)},"close":function($event){_vm.showConfirmRestoring = false}}}):_vm._e()],1),_c('TransitionFade',{attrs:{"body-scroll-lock":""}},[(_vm.showConfirmSaving)?_c('HHImportConfirmSavingModal',{on:{"close":function($event){_vm.showConfirmSaving = false},"accept":function($event){return _vm.acceptChanges(true)}}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
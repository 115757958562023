<template>
  <DecoratedResultModal
    @close="$emit('close')"
    appearance="warning"
    title="Восстановить исходный профиль?"
  >
    <template #default>
      <p>
        Все изменения, связанные с&nbsp;импортом данных из&nbsp;hh.ru, будут
        отменены, и&nbsp;ваш профиль вернется к&nbsp;состоянию до&nbsp;начала
        импорта.
      </p>
      <p>
        Вы можете повторно импортировать информацию из&nbsp;профиля hh.ru
        в&nbsp;меню личного кабинета.
      </p>
    </template>
    <template #actions>
      <BasicButton appearance="primary" size="l" @click="$emit('restore')">
        Восстановить
      </BasicButton>
      <BasicButton appearance="secondary" size="l" @click="$emit('close')">
        Закрыть
      </BasicButton>
    </template>
  </DecoratedResultModal>
</template>

<script>
import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import DecoratedResultModal from 'src/components/DecoratedResultModal/DecoratedResultModal.vue';

export default {
  name: 'HHImportConfirmRestoringModal',
  components: { BasicButton, DecoratedResultModal },
  emits: ['close', 'restore'],
};
</script>

<template>
  <div class="education-course">
    <div class="education-course__title">
      <BasicText weight="bold" size="medium-large">
        <LinkOrText :item="course" />
        <BasicLink
          v-if="
            parseFloat(course.averageRating) && (course.rateLink || course.href)
          "
          :href="course.rateLink || course.href"
          underline="none"
          class="education-course__rating"
        >
          <BasicText weight="bold" size="small" color="purple">
            <InlineAlign gap="small" align="center">
              <template #start>
                <SvgIcon icon="star" color="purple" size="14" />
              </template>
              <template #default>{{ course.averageRating }}</template>
            </InlineAlign>
          </BasicText>
        </BasicLink>
      </BasicText>

      <BasicButton
        v-if="course.rateLink"
        data-label="education_course_rate"
        :href="course.rateLink"
        appearance="rate"
        size="m"
      >
        Оценить
      </BasicButton>
    </div>
    <div v-if="course.author" class="education-course__author">
      Автор курса: <LinkOrText :item="course.author" />
    </div>
    <div
      v-if="course.duration"
      class="education-course__duration"
      v-text="course.duration"
    />
    <div
      v-if="course.message"
      class="education-course__message"
      v-html="course.message"
    />
    <div v-if="course.tags" class="education-course__tags">
      <InlineList :items="course.tags" v-slot="{ item }">
        <BasicLink :href="item.href" :title="item.title" appearance="dark" />
      </InlineList>
    </div>
    <div v-if="course.certificates" class="education-course__certificates">
      <a
        v-for="(certificate, index) in course.certificates"
        :key="index"
        :href="certificate.src"
        target="_blank"
        rel="noreferrer"
        class="education-course__certificate"
      >
        <img
          class="education-course__certificate-image"
          :src="certificate.src"
          alt=""
        />
      </a>
    </div>
  </div>
</template>

<script>
import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicLink from 'src/components/BasicLink/BasicLink.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import InlineAlign from 'src/components/InlineAlign/InlineAlign.vue';
import InlineList from 'src/components/InlineList/InlineList.vue';
import LinkOrText from 'src/components/LinkOrText/LinkOrText.vue';
import BasicText from 'src/components/text/BasicText/BasicText.vue';

export default {
  name: 'EducationCourse',
  components: {
    InlineAlign,
    SvgIcon,
    BasicButton,
    LinkOrText,
    BasicLink,
    InlineList,
    BasicText,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
};
</script>

import { apiUrlV1, cancellableRequest } from 'src/services/api.js';

export const ProfileExpertService = {
  patch({ data }) {
    return cancellableRequest({
      method: 'patch',
      url: `${apiUrlV1}/profile/expert`,
      data,
    });
  },
};

export const ProfileHHImportService = {
  get() {
    return cancellableRequest({
      method: 'GET',
      url: `${apiUrlV1}/profile/import_hh`,
    });
  },
  post({ data }) {
    return cancellableRequest({
      method: 'POST',
      url: `${apiUrlV1}/profile/import_hh`,
      data,
    });
  },
  delete() {
    return cancellableRequest({
      method: 'DELETE',
      url: `${apiUrlV1}/profile/import_hh`,
    });
  },
  patch({ data }) {
    return cancellableRequest({
      method: 'PATCH',
      url: `${apiUrlV1}/profile/import_hh`,
      data,
    });
  },
};

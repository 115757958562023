<template>
  <div
    class="with-spinner-placeholder"
    :class="bem('with-spinner-placeholder', { fullHeight, appearance })"
  >
    <div
      class="with-spinner-placeholder__content"
      :class="
        bem('with-spinner-placeholder__content', {
          hidden: loading,
          appearance,
        })
      "
    >
      <slot />
    </div>
    <transition name="transition-fade">
      <div
        v-if="loading"
        class="with-spinner-placeholder__overlay"
        :class="
          bem('with-spinner-placeholder__overlay', {
            appearance,
          })
        "
      >
        <SvgIcon
          v-if="appearance !== 'career-web'"
          icon="spinner"
          :size="size"
          :color="color"
        />
        <svg
          v-else
          class="with-spinner-placeholder__loader"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M10.524 3.62913C12.1796 3.33721 13.8846 3.54278 15.4233 4.21985C16.9621 4.89693 18.2656 6.01509 19.1688 7.43295C20.0721 8.85081 20.5347 10.5047 20.498 12.1854C20.4613 13.8662 19.9271 15.4983 18.9628 16.8754C17.9985 18.2525 16.6476 19.3128 15.0807 19.9221C13.5139 20.5314 11.8016 20.6624 10.1603 20.2985C8.51897 19.9347 7.02244 19.0923 5.85991 17.8779C4.69737 16.6635 3.92106 15.1316 3.62913 13.476"
            stroke="#5677FC"
            stroke-width="3"
            stroke-linecap="round"
            fill="none"
          />
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

export default {
  name: 'WithSpinnerPlaceholder',
  components: { SvgIcon },
  props: {
    loading: Boolean,
    fullHeight: Boolean,
    size: {
      type: Number,
      default: 20,
    },
    color: String,
    appearance: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'career-web'].includes(value);
      },
    },
  },
  methods: {
    bem,
  },
};
</script>
